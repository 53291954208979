const bannerG = $("#globalBanner");
const bannerGOpen = $("#globalBannerOpen");
const bannerGClose = $("#globalBannerClose");
const bannerGOverlay = $("#globalBannerOverlay");

$(bannerGOpen).click(function() {
  bannerG.addClass("banner-global--open");
  $('body').addClass('body-scroll-block');
});
$(bannerGClose).click(function() {
  bannerG.removeClass("banner-global--open");
  bannerG.remove();
  $('body').removeClass('body-scroll-block');
});

$(bannerGOverlay).click(function() {
  bannerG.removeClass("banner-global--open");
  $('body').removeClass('body-scroll-block');
});
